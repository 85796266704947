import React from "react";
import Earned from "../components/Earned";
import Leaving from "../components/Leaving";
import Share from "../components/Share";
import ShareDone from "../components/ShareDone";
import RewardInformation from "../components/RewardInformation";
import { Spring } from "react-spring/renderprops";
import AuthGiveaways from "../components/AuthGiveaways";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import "./styles/MainPage.css";

import imageCorrect from "../images/icon_done.png";
import close from "../images/icon_delete.png";
import forward from "../images/icon_forward.png";
import Loader from "react-loader-spinner";
import sadImage from "../images/icon_sad.png";
import giftImage from "../images/icon_gift.png";
import winkImage from "../images/icon_wink.png";

import termsTxt from "../info/terms.txt";
import policyTxt from "../info/policy.txt";

import uuid from "react-uuid";

import { createGlobalStyle } from "styled-components";

//import { Mixpanel } from '../Mixpanel.js'
import * as ConfigValues from "../constants.js";

const SCREEN_MAX_WIDTH = 650;

const GlobalFontOpenSans = createGlobalStyle` body { font-family: 'Open Sans', sans-serif; } `;
const GlobalFontRoboto = createGlobalStyle` body { font-family: 'Roboto', sans-serif; }`;

const API_BASE = "https://bonnusapi.azure-api.net/api/";
const API_LANDING = "gvlanding/";
const API_VALIDATE = "gvvalidate/";
const API_REDEEM = "gvredeem/";
const API_SHARE = "share/";

// const API_BASE = "https://bonnusapi30dev.azurewebsites.net/api/"
// const API_LANDING = "giveaways/landing/"
// const API_VALIDATE = "giveaways/validate/"
// const API_REDEEM = "redeemGiveaways/"
// const API_SHARE = "share/"

class MainPage extends React.Component {
  state = {
    pageState: "LOADING_START",
    loadingCreate: false,

    errorMessage: "Recompensa inválida.",

    showAuthGiveaways: false,
    showEarnedReward: false,
    showDataEnrichment: false,
    showClaimedReward: false,

    stateCampaign: undefined,
    configuration: undefined,
    partnerConfig: undefined,
    arrayCampaigns: undefined,

    giveaway: undefined,
    userReference: undefined,
    userId: undefined,
    alertState: false,
    alertMessage: undefined,
    termsText: "",
    helpText: "",
    instructionsText: "",

    rewardGuiderSubtitle: "te premia con:",
    giveawayIsActive: false,
  };

  constructor(props) {
    super(props);

    //this.activityId = props.match.params.activityId
    //this.listState = this.props.location.state
    //this.bonnusId = props.match.params.bonnusId

    this.alias = props.match.params.alias;

    ////Put incoming code from the path in the input field, does not apply for multiple fields
    ////this.fieldValue = ""
    if (props.match.params.field !== undefined)
      this.fieldValue = props.match.params.field;

    this.fieldListValue = [];
    this.dataEnrichListValue = [];
    this.fontId = 1;
    this.currentSlide = 0;
    this.isRedeeming = false;

    this.detectBrowser();

    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();

    fetch(termsTxt)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ termsText: text });
      });

    fetch(policyTxt)
      .then((r) => r.text())
      .then((text) => {
        this.setState({ helpText: text });
      });
  }

  updateDimensions = () => {
    if (this.ismounted) {
      this.setState({ state: this.state });
    }
  };

  componentDidMount() {
    this.ismounted = true;

    if (this.alias !== undefined) {
      this.fetchGiveawaysAuth();
    } else {
      this.setState({
        pageState: "NOTHING",
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.giveawayIsActive) {
      this.integrateChat();
    }
  }

  integrateChat = () => {
    const script = document.createElement("script");
    script.src = "//eu.fw-cdn.com/12817170/793962.js";
    script.async = true;
    script.onload = () => {
      if (window.fcWidget) {
        window.fcWidget.init();

        // Configurar el usuario
        window.fcWidget.setExternalId("202000001781");
        window.fcWidget.user.setFirstName("Hiram");
        window.fcWidget.user.setEmail("hiram.hernandez@bonnus.me");
        window.fcWidget.user.setProperties({
          cf_plan: "Pro", // propiedad meta 1
          cf_status: "Active", // propiedad meta 2
        });
      } else {
        console.error("fcWidget no está disponible");
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  };

  componentWillUnmount() {
    this.ismounted = false;
  }

  fetchGiveawaysAuth = async () => {
    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "41049999bd394b9e89dc297a7b23559a",
      },
    };

    try {
      const response = await fetch(
        `${API_BASE}${API_LANDING}${this.alias}`,
        settings
      );
      const responseData = await response.json();
      console.log(
        `fetchGiveawaysAuth ${this.API_LANDING} - ${this.alias} ---->`,
        responseData
      );
      const { layoutConfiguration, giveaway, message } = responseData;

      if (giveaway !== undefined || layoutConfiguration !== undefined) {
        //Mixpanel.track(ConfigValues.MIXPANEL_TAG_FIELD, { "giveawayAlias" : this.props.alias} );

        //Cambiamos el estado del giveaway
        this.setState({ giveawayIsActive: giveaway.isActive });

        if (giveaway.private) {
          this.setState({
            pageState: "AUTH_GIVEAWAYS",
            showAuthGiveaways: true,
            giveaway: giveaway,
            configuration: layoutConfiguration,
          });
        } else {
          const deviceId = localStorage.getItem("bonnusGiveawaysDeviceId");
          if (deviceId == null || deviceId === "") {
            localStorage.setItem("bonnusGiveawaysDeviceId", uuid());
          }

          this.setState({
            giveaway: giveaway,
            configuration: layoutConfiguration,
          });
          this.fetchGiveawaysBuilder(null);
        }
      } else {
        if (message !== undefined) {
          this.setState({
            pageState: "ERROR_LOADING_START",
            errorMessage: message,
            configuration: undefined,
          });
        } else {
          this.setState({
            pageState: "ERROR_LOADING_START",
            configuration: undefined,
          });
        }
      }
    } catch (error) {
      this.setState({
        pageState: "ERROR_LOADING_START",
        messageError: error,
        configuration: undefined,
      });
    }
  };

  onClickFieldsLayout = (values, dataEnrich) => {
    this.fetchGiveawaysBuilder(values, dataEnrich);
  };

  fetchGiveawaysBuilder = async (fieldValues, dataEnrichListValues = []) => {
    this.setState({
      loadingCreate: true,
    });

    let data = { giveawayId: this.state.giveaway.id };
    if (fieldValues != null && fieldValues.length !== 0) {
      data.dataEnrich = "";
      let fieldNumber = 1;
      fieldValues.forEach((element) => {
        data[`field${fieldNumber}`] = element;
        fieldNumber++;
      });
    } else {
      data.deviceId = localStorage.getItem("bonnusGiveawaysDeviceId");
    }

    if (dataEnrichListValues !== null && dataEnrichListValues.length > 0) {
      let listToString = "";
      dataEnrichListValues.forEach((element) => {
        listToString += `"${element.name}": "${element.value}",`;
      });
      data.dataEnrich = `{${listToString.substring(
        0,
        listToString.length - 1
      )}}`;
    }

    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "41049999bd394b9e89dc297a7b23559a",
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(`${API_BASE}${API_VALIDATE}`, settings);
      const responseData = await response.json();
      const {
        campaigns,
        layoutConfiguration,
        configuration,
        userReference,
        userid,
        deviceId,
        bonnusId,
        code,
        message,
      } = responseData;

      if (
        campaigns !== undefined ||
        layoutConfiguration !== undefined ||
        configuration !== undefined
      ) {
        if (campaigns.length === 1) {
          //Mixpanel.track(ConfigValues.MIXPANEL_TAG_FIELD, {"earnedCampaignId": campaigns[0].id});
          this.setState({
            pageState: "SUCCESS_LOAD_CARD",
            loadingCreate: false,

            showAuthGiveaways: false,
            showEarnedReward: !campaigns[0].wasRedeemed,
            showDataEnrichment: false,
            showClaimedReward: campaigns[0].wasRedeemed,

            stateCampaign: campaigns[0],
            configuration: layoutConfiguration,
            partnerConfig: configuration,
            arrayCampaigns: undefined,

            userReference: userReference,
            userId: userid,
            deviceId: deviceId,
            termsText: campaigns[0].terms,
            instructionsText: campaigns[0].instructions,
            helpText: configuration.support,
          });
        } else {
          //campaigns.map( (item) => (
          //    Mixpanel.track(ConfigValues.MIXPANEL_TAG_FIELD, {"earnedCampaignId": item.id})
          //))
          this.setState({
            pageState: "SUCCESS_LOADING_CAROUSEL",
            loadingCreate: false,

            showAuthGiveaways: false,
            showEarnedReward: true,
            showDataEnrichment: false,
            showClaimedReward: false,

            stateCampaign: undefined,
            configuration: layoutConfiguration,
            partnerConfig: configuration,
            arrayCampaigns: campaigns,

            userReference: userReference,
            userId: userid,
            deviceId: deviceId,
            helpText: configuration.support,
          });
        }
      } else if (bonnusId !== undefined) {
        this.bonnusId = bonnusId;
        this.fetchRedeemReadOnly();
      } else if (
        code !== undefined &&
        code === 400 &&
        this.state.pageState !== "LOADING_START"
      ) {
        this.setState({
          loadingCreate: false,
          alertState: true,
          alertMessage: message,
        });
      } else if (
        code !== undefined &&
        code === 400 &&
        this.state.pageState === "LOADING_START"
      ) {
        this.setState({
          pageState: "ERROR_LOADING_START",
          errorMessage: message,
        });
      } else {
        this.setState({
          pageState: "ERROR_LOADING_START",
        });
      }
    } catch (error) {
      this.setState({
        pageState: "ERROR_LOADING_START",
      });
    }
  };

  fetchRedeemReadOnly = async () => {
    const data = {
      bonnusId: this.bonnusId,
    };
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "41049999bd394b9e89dc297a7b23559a",
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(`${API_BASE}${API_REDEEM}`, settings);
      const { bonnus, layoutConfiguration, configuration } =
        await response.json();

      if (bonnus !== undefined) {
        this.setState({
          pageState: "SUCCESS_LOAD_CARD",
          loadingCreate: false,
          showEarnedReward: false,
          showDataEnrichment: false,
          showClaimedReward: true,
          stateCampaign: bonnus,
          configuration: layoutConfiguration,
          partnerConfig: configuration,
        });
      } else {
        this.setState({
          pageState: "SUCCESS_LOAD_CARD",
          loadingCreate: false,
        });
      }
    } catch (error) {
      this.setState({
        pageState: "SUCCESS_LOAD_CARD",
        loadingCreate: false,
      });
    }
  };

  onDataEnrichment = (e) => {
    try {
      window.AndroidInterface.onMessage("Claim Clicked");
    } catch (error) {}
    try {
      window.webkit.messageHandlers.AppleInterface.postMessage("Claim Clicked");
    } catch (error) {}

    this.setState({
      pageState: "SUCCESS_LOAD_CARD",
      loadingCreate: true,
      showDataEnrichment: false,
      showClaimedReward: false,
      configuration: this.state.configuration,
    });

    setTimeout(() => {
      this.setState({
        pageState: "SUCCESS_LOAD_CARD",
        loadingCreate: false,
        showDataEnrichment: true,
        showClaimedReward: false,
        configuration: this.state.configuration,
      });
    }, 1000);
  };

  onEmailChange = (text) => {
    this.emailData = text;
  };

  fetchRedeem = async (id) => {
    try {
      window.AndroidInterface.onMessage("Generate Clicked");
    } catch (error) {}
    try {
      window.webkit.messageHandlers.AppleInterface.postMessage(
        "Generate Clicked"
      );
    } catch (error) {}
    try {
      window.parent.postMessage("Generate Clicked", "*");
    } catch (error) {}

    this.setState({
      loadingCreate: true,
    });

    let data = {};
    if (this.state.giveaway.private) {
      data = {
        giveawayId: this.state.giveaway.id,
        campaignId: id,
        data: {
          id: this.state.userId,
          birthDate: "",
          zipCode: "",
          gender: "",
          geo: "",
          email: this.emailData,
          name: "",
          lastName: "",
          city: "",
          state: "",
        },
      };
    } else {
      data = {
        giveawayId: this.state.giveaway.id,
        campaignId: id,
        data: {
          deviceId: this.state.deviceId,
          birthDate: "",
          zipCode: "",
          gender: "",
          geo: "",
          email: this.emailData,
          name: "",
          lastName: "",
          city: "",
          state: "",
        },
      };
    }

    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "41049999bd394b9e89dc297a7b23559a",
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${API_BASE}${API_REDEEM}`, settings);
      const { bonnus } = await response.json();

      if (bonnus !== undefined) {
        //Mixpanel.track(ConfigValues.MIXPANEL_TAG_FIELD, {"redeemCampaignId": bonnus.id});
        this.isRedeeming = true;
        this.setState({
          pageState: "SUCCESS_LOAD_CARD",
          loadingCreate: false,
          showEarnedReward: false,
          showDataEnrichment: false,
          showClaimedReward: true,
          stateCampaign: bonnus,
        });
      } else {
        await this.setState({
          pageState: "ERROR_LOADING_START",
          loadingCreate: false,
        });
      }
    } catch (error) {
      await this.setState({
        pageState: "ERROR_LOADING_START",
        loadingCreate: false,
      });
    }
  };

  onTermsConditionsGiveaways = (e) => {
    this.setState({
      pageState: "TERMS_CONDITIONS_GIVEAWAYS",
    });
  };

  onTermsConditions = (e) => {
    this.setState({
      pageState: "TERMS_CONDITIONS",
    });
  };

  onHelp = (e) => {
    this.setState({
      pageState: "HELP",
    });
  };

  onInstructions = (e) => {
    this.setState({
      pageState: "INSTRUCTIONS",
    });
  };

  onShare = (e) => {
    this.setState({
      pageState: "SHARE",
    });
  };

  onLeave = (e) => {
    if (this.isDetail !== undefined && this.isDetail === true) {
      this.props.history.goBack();
    } else if (this.state.showClaimedReward) {
      try {
        if (this.isMobile) {
          try {
            window.AndroidInterface.onMessage("LeaveOK");
          } catch (error) {}
          try {
            window.webkit.messageHandlers.AppleInterface.postMessage("LeaveOK");
          } catch (error) {}
          try {
            window.parent.postMessage("LeaveOK", "*");
          } catch (error) {}
        } else {
          if (
            this.state.configuration.buttonClose !== undefined &&
            this.state.configuration.buttonClose.web !== undefined &&
            this.state.configuration.buttonClose.web.action !== undefined
          )
            window.run(this.state.configuration.buttonClose.web.action);
        }
      } catch (error) {}
    } else {
      this.setState({
        pageState: "LEAVE",
      });
    }
  };

  detectBrowser = () => {
    var ua = navigator.userAgent;
    try {
      if (/Android|iPhone|iPad|iPod/i.test(ua)) this.isMobile = true;
      else this.isMobile = false;
    } catch (error) {}
    return ua;
  };

  onShowReward = (e) => {
    setTimeout(() => {
      if (this.state.showAuthGiveaways) {
        this.setState({
          pageState: "AUTH_GIVEAWAYS",
        });
      } else if (
        this.state.showClaimedReward ||
        this.state.arrayCampaigns === undefined
      ) {
        this.setState({
          pageState: "SUCCESS_LOAD_CARD",
        });
      } else {
        this.setState({
          pageState: "SUCCESS_LOADING_CAROUSEL",
        });
      }
    }, 150);
  };

  onLeaveReward = (e) => {
    setTimeout(() => {
      try {
        if (this.isMobile) {
          try {
            window.AndroidInterface.onMessage("Leave Clicked");
          } catch (error) {}
          try {
            window.webkit.messageHandlers.AppleInterface.postMessage(
              "Leave Clicked"
            );
          } catch (error) {}
          try {
            window.parent.postMessage("Leave Clicked", "*");
          } catch (error) {}
        } else {
          if (
            this.state.configuration.buttonClose !== undefined &&
            this.state.configuration.buttonClose.web !== undefined &&
            this.state.configuration.buttonClose.web.action !== undefined
          )
            window.run(this.state.configuration.buttonClose.web.action);
        }
      } catch (error) {}
    }, 150);
  };

  onShareReward = async (name, otherName, otherEmail) => {
    try {
      try {
        window.AndroidInterface.onMessage("Share Clicked");
      } catch (error) {}
      try {
        window.webkit.messageHandlers.AppleInterface.postMessage(
          "Share Clicked"
        );
      } catch (error) {}
      try {
        window.parent.postMessage("Share Clicked", "*");
      } catch (error) {}
    } catch (error) {}

    this.setState({
      pageState: this.state.pageState,
      loadingCreate: true,
      showDataEnrichment: this.state.showDataEnrichment,
      showClaimedReward: this.state.showClaimedReward,
      configuration: this.state.configuration,
    });
    const data = {
      data: {
        email: otherEmail,
        name: otherName,
      },
    };
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": "41049999bd394b9e89dc297a7b23559a",
      },
      body: JSON.stringify(data),
    };
    try {
      await fetch(`${API_BASE}${API_SHARE}${this.activityId}`, settings);
      await this.setState({
        pageState: "SHARE_DONE",
        loadingCreate: false,
        shareName: name,
        shareOtherName: otherName,
        shareOtherEmail: otherEmail,
      });
    } catch (error) {
      this.setState({
        pageState: "SHARE",
        loadingCreate: false,
        shareName: name,
        shareOtherName: otherName,
        shareOtherEmail: otherEmail,
      });
    }
  };

  renderSlides = () => {
    return this.state.arrayCampaigns.map((item, index) => (
      <Earned
        key={index}
        //Campaign
        campaignInfo={item}
        //Images
        correctMailImg={imageCorrect}
        leaveImage={close}
        forwardImage={forward}
        //Texts
        emailData={this.emailData}
        buttonClaim={
          this.state.partnerConfig !== undefined &&
          this.state.partnerConfig.buttonAccept !== undefined
            ? this.state.partnerConfig.buttonAccept
            : "CANJEAR AHORA"
        }
        buttonGenerate="GENERAR AHORA"
        terms="Términos y Condiciones"
        help="Ayuda"
        buttonConvert="IR AL SITIO"
        detailTitle="INSTRUCCIONES"
        detailTitle_mas="Ver más"
        shareText="COMPARTIR"
        backText="ATRÁS"
        rewardGuider={
          this.state.partnerConfig !== undefined &&
          this.state.partnerConfig.reward_subtitle !== undefined
            ? this.state.partnerConfig.reward_subtitle
            : this.state.rewardGuiderSubtitle
        }
        inputPlaceholder="Introduce aquí tu email"
        emailInstructions="Introduce tu email para reclamar tu recompensa"
        // Callbacks
        showDataEnrichmentCallback={this.onDataEnrichment}
        createRewardCallback={this.fetchRedeem}
        termsCallback={this.onTermsConditions}
        helpCallback={this.onHelp}
        instructionsCallback={this.onInstructions}
        shareCallback={this.onShare}
        leaveCallback={this.onLeave}
        emailCallback={this.onEmailChange}
        //Flags
        showEarnedReward={!item.wasRedeemed}
        showDataEnrichment={this.state.showDataEnrichment}
        showClaimedReward={item.wasRedeemed}
        isDetail={this.isDetail}
        //Configuration
        configuration={this.state.configuration}
        partnerConfig={this.state.partnerConfig}
        //Badge
        useBadge
      />
    ));
  };

  onChangeSlider = (index) => {
    this.currentSlide = index;
  };

  alertChangeState = (value) => {
    this.setState({
      alertState: value,
    });
  };

  extraInformationAlert = (index) => {
    try {
      this.setState({
        alertState: true,
        alertMessage: this.state.giveaway.fields[index].help_text,
      });
    } catch (ex) {}
  };

  onChangeFieldValue = (value) => {
    this.fieldListValue = value;
  };

  onChangeDataEnrichListValue = (value) => {
    this.dataEnrichListValue = value;
  };

  render() {
    let loader = (
      <React.Fragment>
        <div className="loader__background"></div>
        <Loader
          className="loader"
          type="Oval"
          color="#FFFFFF"
          height={80}
          width={80}
        />
      </React.Fragment>
    );

    let loaderState;
    if (this.state.loadingCreate === true) loaderState = loader;
    else loaderState = <div></div>;

    let globalBackground = {};
    if (
      this.state.configuration !== undefined &&
      this.state.configuration.z_gb !== undefined
    )
      globalBackground = { background: this.state.configuration.z_gb };
    let globalBackgroundEmpty = {};
    let globalBackgroundState;
    if (this.state.configuration !== undefined) {
      globalBackgroundState = globalBackground;
    } else {
      globalBackgroundState = globalBackgroundEmpty;
    }

    let carouselInstructionsAndDotsStyle = {};
    if (
      this.state.configuration !== undefined &&
      this.state.configuration.z_ab_txtClr !== undefined
    ) {
      carouselInstructionsAndDotsStyle.color =
        this.state.configuration.z_ab_txtClr;
    }

    if (window.innerWidth > SCREEN_MAX_WIDTH) {
      globalBackgroundState.justifyContent = "center";
    } else {
      globalBackgroundState.justifyContent = "center";
    }

    let helpText;
    if (this.state.partnerConfig !== undefined)
      helpText = this.state.partnerConfig.support;
    else helpText = this.state.helpText;

    let globalFont;
    switch (this.fontId) {
      case 1:
        globalFont = <GlobalFontOpenSans />;
        break;
      case 2:
        globalFont = <GlobalFontRoboto />;
        break;
      default:
        globalFont = <GlobalFontOpenSans />;
        break;
    }

    switch (this.state.pageState) {
      case "LOADING_START":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {loader}
          </div>
        );
      case "ERROR_LOADING_START":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            <div className="Message">{this.state.errorMessage}</div>
          </div>
        );
      case "AUTH_GIVEAWAYS":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}
            <AuthGiveaways
              giveaway={this.state.giveaway}
              //Callbacks
              onClickFieldsLayout={this.onClickFieldsLayout}
              extraInfoCallback={this.extraInformationAlert}
              termsCallback={this.onTermsConditionsGiveaways}
              helpCallback={this.onHelp}
              //Alert State
              alertState={this.state.alertState}
              alertChangeState={this.alertChangeState}
              alertMessage={this.state.alertMessage}
              //Texts
              rewardGuider={this.state.rewardGuiderSubtitle}
              inputPlaceholder="Introduce aquí tu email"
              emailInstructions="Introduce tu email para reclamar tu recompensa"
              alertOkText="Aceptar"
              //Field value
              fieldListValue={this.fieldListValue}
              changedValue={this.onChangeFieldValue}
              fieldValue={this.fieldValue}
              //dataErich
              dataEnrichListValue={this.dataEnrichListValue}
              changeDataEnrich={this.onChangeDataEnrichListValue}
              //Configuration
              configuration={this.state.configuration}
              //Alias
              alias={this.alias}
            />
            {loaderState}
          </div>
        );
      case "SUCCESS_LOADING_CAROUSEL":
        return (
          <div className="Main__Carousel" style={globalBackgroundState}>
            {globalFont}
            <Spring from={{ marginTop: -500 }} to={{ marginTop: 0 }}>
              {(props) => (
                <div style={props}>
                  <Slider
                    adaptiveHeight={true}
                    dots={true}
                    infinite={false}
                    initialSlide={this.currentSlide}
                    afterChange={this.onChangeSlider}
                  >
                    {this.renderSlides()}
                  </Slider>

                  <div className="carousel__instructions-container">
                    <div
                      className="carousel__instructions"
                      style={carouselInstructionsAndDotsStyle}
                    >
                      {this.state.partnerConfig.carruselText}
                    </div>
                  </div>
                </div>
              )}
            </Spring>
            {loaderState}
          </div>
        );
      case "SUCCESS_LOAD_CARD":
        let marginFromStart = 0;
        if (!this.state.showClaimedReward || this.isRedeeming) {
          marginFromStart = 0;
          this.isRedeeming = false;
        } else if (this.state.showClaimedReward) {
          marginFromStart = -500;
        }
        if (this.state.arrayCampaigns === undefined) marginFromStart = -500;

        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}

            <Spring from={{ marginTop: marginFromStart }} to={{ marginTop: 0 }}>
              {(props) => (
                <div style={props}>
                  <Earned
                    campaignInfo={this.state.stateCampaign}
                    //Images
                    correctMailImg={imageCorrect}
                    leaveImage={close}
                    forwardImage={forward}
                    //Texts
                    emailData={this.emailData}
                    buttonClaim={
                      this.state.partnerConfig !== undefined &&
                      this.state.partnerConfig.buttonAccept !== undefined
                        ? this.state.partnerConfig.buttonAccept
                        : "CANJEAR AHORA"
                    }
                    buttonGenerate="GENERAR AHORA"
                    terms="Términos y Condiciones"
                    help="Ayuda"
                    buttonConvert="IR AL SITIO"
                    detailTitle="INSTRUCCIONES"
                    detailTitle_mas="Ver más"
                    shareText="COMPARTIR"
                    backText="ATRÁS"
                    rewardGuider={
                      this.state.partnerConfig !== undefined &&
                      this.state.partnerConfig.reward_subtitle !== undefined
                        ? this.state.partnerConfig.reward_subtitle
                        : this.state.rewardGuiderSubtitle
                    }
                    inputPlaceholder="Introduce aquí tu email"
                    emailInstructions="Introduce tu email para reclamar tu recompensa"
                    // Callbacks
                    showDataEnrichmentCallback={this.onDataEnrichment}
                    createRewardCallback={this.fetchRedeem}
                    termsCallback={this.onTermsConditions}
                    helpCallback={this.onHelp}
                    instructionsCallback={this.onInstructions}
                    shareCallback={this.onShare}
                    leaveCallback={this.onLeave}
                    emailCallback={this.onEmailChange}
                    //Flags
                    showEarnedReward={this.state.showEarnedReward}
                    showDataEnrichment={this.state.showDataEnrichment}
                    showClaimedReward={this.state.showClaimedReward}
                    isDetail={this.isDetail}
                    //Configuration
                    configuration={this.state.configuration}
                    partnerConfig={this.state.partnerConfig}
                  />
                </div>
              )}
            </Spring>

            {loaderState}
          </div>
        );
      case "TERMS_CONDITIONS_GIVEAWAYS":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}
            <RewardInformation
              title="Términos y Condiciones"
              text={this.state.termsText}
              buttonText="Regresar"
              onBack={this.onShowReward}
              configuration={this.state.configuration}
            />
          </div>
        );
      case "TERMS_CONDITIONS":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}
            {this.state.showClaimedReward ||
            this.state.arrayCampaigns === undefined ? (
              <RewardInformation
                title="Términos y Condiciones"
                text={this.state.stateCampaign.terms}
                buttonText="Regresar"
                onBack={this.onShowReward}
                configuration={this.state.configuration}
              />
            ) : (
              <RewardInformation
                title="Términos y Condiciones"
                text={this.state.arrayCampaigns[this.currentSlide].terms}
                buttonText="Regresar"
                onBack={this.onShowReward}
                configuration={this.state.configuration}
              />
            )}
          </div>
        );
      case "HELP":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}
            <RewardInformation
              title="Ayuda"
              text={helpText}
              buttonText="Regresar"
              onBack={this.onShowReward}
              configuration={this.state.configuration}
            />
          </div>
        );
      case "INSTRUCTIONS":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}
            <RewardInformation
              title="Instrucciones"
              text={this.state.stateCampaign.instructions}
              buttonText="Regresar"
              onBack={this.onShowReward}
              configuration={this.state.configuration}
            />
          </div>
        );
      case "SHARE":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}
            <Share
              title="COMPARTIR RECOMPENSA"
              text="Indica tus datos y los del destinatario para poder compartir tu recompensa"
              buttonShare="COMPARTIR"
              buttonCancel="CANCELAR"
              onBack={this.onShowReward}
              giftIcon={giftImage}
              correctImg={imageCorrect}
              ownInfo="TU NOMBRE"
              otherInfo="DESTINATARIO"
              namePlaceholder="Tu nombre aquí"
              otherNamePlaceholder="Nombre del destinatario"
              otherEmailPlaceholder="Email del destinatario"
              shareRewardCallback={this.onShareReward}
              configuration={this.state.configuration}
            />
            {loaderState}
          </div>
        );
      case "LEAVE":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}
            <Leaving
              title="Si realizas esta acción perderás tu recompensa para siempre."
              text="¿Deseas continuar?"
              buttonStay="Volver a la recompensa"
              buttonLeave="Continuar"
              onBack={this.onShowReward}
              onLeave={this.onLeaveReward}
              sadIcon={sadImage}
              configuration={this.state.configuration}
            />
          </div>
        );
      case "SHARE_DONE":
        return (
          <div className="MainPage" style={globalBackgroundState}>
            {globalFont}
            <ShareDone
              shareName={this.state.shareName}
              shareOtherName={this.state.shareOtherName}
              shareOtherEmail={this.state.shareOtherEmail}
              title="¡Felicidades!"
              text="Tu recompensa ha sido compartida con:"
              continueText="CONTINUAR"
              happyIcon={winkImage}
              isMobile={this.isMobile}
              configuration={this.state.configuration}
            />
          </div>
        );
      default:
        return <div />;
    }
  }
}

export default MainPage;
